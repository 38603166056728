.CircuitPhotosComponent .card-header {
  font-weight: bold;
}

.CircuitPhotosComponent .collapse-button {
  float: right;
  padding: 0.25px 15px;
}

.CircuitPhotosComponent .photo-container {
  max-height: 400px;
  overflow: auto;
}



.CircuitPhotosComponent .photo {
  padding: 0.5rem;
  border: 1px blue solid;
  color: blue;
  cursor: pointer;
  margin: 0.5rem 0;
}

.CircuitPhotosComponent .photo-container.disabled .photo {
  cursor: auto;
  border-color: rgba(0,0,0,0.7);
  color: rgba(0,0,0,0.7);
}

.photo-fullscreen {
  max-width: 100%;
  max-height: 80vh;
}

.CircuitPhotosComponent .photo.active {
  color: #1e3575;
  border-color: #1e3575;
}