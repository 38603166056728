.PoleReinforcementInspectionResolve .card-header {
  font-weight: bold;
}

.PoleReinforcementInspectionResolve .mt-05rem {
  margin-top: 0.5rem;
}

.PoleReinforcementInspectionResolve .mt-1rem {
  margin-top: 1rem;
}

.PoleReinforcementInspectionResolve .mb-1rem {
  margin-bottom: 1rem;
}

.PoleReinforcementInspectionResolve .display-card p {
  margin-bottom: 0.25rem;
}
