.PoleReinforcementInspection {
  margin-top: 3rem !important;
}

.PoleReinforcementInspection .cards-container {
  margin-top: 0.5rem;
  padding: 0rem;
}

.PoleReinforcementInspection .overflow-500 {
  max-height: 500px;
  overflow: scroll;
  font-size: small;
}
