.Release .MainCard {
  margin-bottom: 1rem;
}

.Release .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.Release .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.Release .card-text {
  color: #000000;
}

.Release .inspectButton {
  margin-bottom: 1.5rem;
}

.Release .updates-container {
  margin-bottom: 1.5rem;
}

.Release .changes-container {
  margin-bottom: 1.5rem;
}

.Release .margin-bottom-1rem {
  margin-bottom: 1rem;
}

.Release .card-title {
  margin-bottom: 0.1rem;
}

.Release .changes-body {
  padding-bottom: 0.5rem;
}
