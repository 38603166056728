.User .UserCard {
  margin-bottom: 1rem;
}

.User .nav-tabs {
  margin-top: 0.25rem;
}

.User .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.User .checkBoxContainer {
  margin-bottom: 1rem;
}

.User .editButton {
  margin-top: 1rem;
}

.User .deleteButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.User .Org {
  margin-bottom: 1rem;
}

.User .Role {
  margin-top: 1rem;
}

.User .Registration {
  margin-top: 1rem;
}
