.ComplexRulesList h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 28px;
}

.ComplexRulesList p {
  color: #999;
}

.ComplexRulesList .Download {
  text-align: center;
}

.ComplexRulesList .Scrollable {
  overflow-x: scroll;
}

.ComplexRulesList .CapPhrase {
  text-transform: capitalize;
}

.ComplexRulesList .card-header {
  font-weight: bold;
}

.ComplexRulesList .search-engine {
  margin-top: 1rem;
}

.ComplexRulesList .NewSearch {
  width: 100%;
  text-align: right;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.ShowRuleErrorsModal .modal-body {
  overflow-y: auto;
  max-height: 600px;
}

.ShowRuleErrorsModal .card {
  margin-bottom: 15px;
}

.ComplexRulesList .RuleCards {
  margin-top: 1rem;
}

.ComplexRulesList .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.ComplexRulesList .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}
