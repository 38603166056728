.MyDriveSearch h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 28px;
}

.MyDriveSearch p {
  color: #999;
}

.MyDriveSearch .Download {
  text-align: center;
}

.MyDriveSearch .Scrollable {
  overflow-x: scroll;
}

.MyDriveSearch .CapPhrase {
  text-transform: capitalize;
}

.MyDriveSearch .card-header {
  font-weight: bold;
}

.MyDriveSearch .NewSearch {
  width: 100%;
  text-align: right;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.ShowRuleErrorsModal .modal-body {
  overflow-y: auto;
  max-height: 600px;
}

.ShowRuleErrorsModal .card {
  margin-bottom: 15px;
}

.MyDriveSearch .fileCards {
  margin-top: 1rem;
}

.MyDriveSearch .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.MyDriveSearch .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.MyDriveSearch .search-engine {
  margin-top: 1rem;
}
