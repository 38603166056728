.ComplexRule .RuleCard {
  margin-bottom: 1rem;
}

.ComplexRule .SupportCard {
  margin-bottom: 1rem;
}

.ComplexRule .nav-tabs {
  margin-top: 0.5rem;
}

.ComplexRule .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.ComplexRule .card-text {
  color: #000000;
}

.ComplexRule .card-header {
  color: #000000;
}

.ComplexRule .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.ComplexRule .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.ComplexRule .SupportFileCard {
  background-color: #f7f7f7;
}

.ComplexRule .ErrorFileCard {
  background-color: #f7f7f7;
}

.ComplexRule .editButton {
  margin-top: 1rem;
}

.ComplexRule .deleteButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.ComplexRule .ruleName {
  margin-bottom: 1rem;
}

.ComplexRule .ruleDescription {
  margin-bottom: 1rem;
}

.ComplexRule .applyTo {
  margin-top: 1rem;
}

.ComplexRule .ruleActive {
  margin-bottom: 1rem;
}

.ComplexRule .ruleDisplay {
  margin-bottom: 1rem;
}

.ComplexRule .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.ComplexRule .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}
