.DataField .FieldCard {
  margin-bottom: 1rem;
}

.DataField .SupportCard {
  margin-bottom: 1rem;
}

.DataField .nav-tabs {
  margin-top: 0.5rem;
}

.DataField .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.DataField .card-text {
  color: #000000;
}

.DataField .card-text:last-child {
  margin-bottom: 1rem;
}

.DataField .card-header {
  color: #000000;
}

.DataField .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.DataField .card-header-warning {
  color: #ffffff;
  background-color: #ffc107;
}

.DataField .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.DataField .SupportFileCard {
  background-color: #f7f7f7;
}

.DataField .ErrorFileCard {
  background-color: #f7f7f7;
}

.DataField .mt-1rem {
  margin-top: 1rem;
}

.DataField .mt-2rem {
  margin-top: 2rem;
}

.DataField .mb-0rem {
  margin-bottom: 0rem;
}

.DataField .mb-1rem {
  margin-bottom: 1rem;
}

.DataField .deleteButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.DataField .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.DataField .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.DataField .discard-cancel-buttons {
  margin-top: 1rem;
  margin-left: 0.5rem;
}
