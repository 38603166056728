.CircuitAudit .card-header-primary {
  color: #ffffff;
  background-color: rgb(0, 123, 255);
}

.CircuitAudit .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.CircuitAudit .card-header-warning {
  color: #ffffff;
  background-color: rgb(255, 193, 7);
}

.CircuitAudit .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.CircuitAudit .mt-05rem {
  margin-top: 0.5rem;
}

.CircuitAudit .mt-1rem {
  margin-top: 1rem;
}

.CircuitAudit .mb-1rem {
  margin-bottom: 1rem;
}

.CircuitAudit .mb-0rem {
  margin-bottom: 0rem;
}

.CircuitAudit .mb-05rem {
  margin-bottom: 0.5rem;
}

.CircuitAudit .mr-05rem {
  margin-right: 0.5rem;
}

.CircuitAudit .nav-tabs {
  margin-top: 0.25rem;
}

.CircuitAudit .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.CircuitAudit .card-text {
  color: #000000;
}

.CircuitAudit .collapse-button {
  float: right;
  padding: 0.25px 15px;
}

.CircuitAudit .task-group {
  margin: 0.25rem 0rem;
}

.CircuitAudit .display-container {
  margin-top: 0.5rem;
  padding: 0.75rem;
}

.CircuitAudit .cards-container {
  margin-top: 0.5rem;
  padding: 0rem;
}

.CircuitAudit .display-card {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}

.CircuitAudit .display-card p {
  margin-bottom: 0.25rem;
}

.CircuitAudit .inspect-obj-card {
  max-height: 20rem;
  padding: 5px;
  margin-top: 0.5rem;
}

.CircuitAudit .overflow-500 {
  max-height: 500px;
  overflow: scroll;
  font-size: small;
}
