.PhotoPreview .mb-05rem {
  margin-bottom: 0.5rem;
}

.PhotoPreview .photo-des {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.PhotoPreview .align-preview {
  text-align: center;
}
