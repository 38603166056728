.BuilderTest .mt-1rem {
  margin-top: 1rem;
}

.BuilderTest .ml-1rem {
  margin-left: 1rem;
}

.BuilderTest .mb-1rem {
  margin-bottom: 1rem;
}

.BuilderTest .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.BuilderTest .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.BuilderTest .badge-spacing {
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

.BuilderTest .feedback {
  margin-bottom: 0.1rem;
}
