.Uploader .custom-file {
  margin-bottom: 25px;
}

.Uploader .card-header {
  font-weight: bold;
}

.Update .msgModal {
  color: #333;
  font-family: "Open Sans";
}

.Uploader .CapPhrase {
  text-transform: capitalize;
}

.Uploader .NewUpload {
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
  margin-top: -10px;
}

.Uploader .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.Uploader .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.Uploader .mt-1rem {
  margin-top: 1rem;
}
