.ShowUserInquiries .card-margins {
  margin-top: 15px;
}

.ShowUserInquiries .mt-1rem {
  margin-top: 1rem;
}

.ShowUserInquiries .mb-1rem {
  margin-bottom: 1rem;
}

.ShowUserInquiries .ml-075rem {
  margin-left: 0.75rem;
}

.ShowUserInquiries .ml-05rem {
  margin-left: 0.5rem;
}

.ShowUserInquiries .mb-0rem {
  margin-bottom: 0rem;
}

.ShowUserInquiries .mb-05rem {
  margin-bottom: 0.5rem;
}
