.UploaderZipDataFactory .card-header {
  font-weight: bold;
}

.UploaderZipDataFactory .mt-1rem {
  margin-top: 1rem;
}

.UploaderZipDataFactory .mt-05rem {
  margin-top: 0.5rem;
}

.UploaderZipDataFactory .mt-075rem {
  margin-top: 0.75rem;
}

.UploaderZipDataFactory .mb-1rem {
  margin-bottom: 1rem;
}

.UploaderZipDataFactory .mr-05rem {
  margin-right: 0.5rem;
}

.UploaderZipDataFactory .overflow-500 {
  max-height: 500px;
  overflow: scroll;
  font-size: small;
  padding: 0.5rem;
}

.UploaderZipDataFactory .collapse-button {
  float: right;
  padding: 0.25px 15px;
}

.UploaderZipDataFactory .footer-center-text {
  text-align: center;
}
