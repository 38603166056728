.UploadedFile .UploadCard {
  margin-bottom: 1rem;
}

.UploadedFile .SupportCard {
  margin-bottom: 1rem;
}

.UploadedFile .nav-tabs {
  margin-top: 0.25rem;
}

.UploadedFile .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.UploadedFile .card-text {
  color: #000000;
}

.UploadedFile .card-header {
  color: #000000;
}

.UploadedFile .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.UploadedFile .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.UploadedFile .SupportFileCard {
  background-color: #f7f7f7;
}

.UploadedFile .ErrorFileCard {
  background-color: #f7f7f7;
}

.UploadedFile .mt-1rem {
  margin-top: 1rem;
}

.UploadedFile .mb-0rem {
  margin-bottom: 0rem;
}

.UploadedFile .overflow-500 {
  max-height: 500px;
  overflow: scroll;
  font-size: small;
}
