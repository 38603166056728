.ReinforcementInspectionPole .pl-1rem {
  padding-left: 1rem;
}

.ReinforcementInspectionPole .cat-badge {
  font-size: 1rem;
  padding: 0.5rem;
}

.ReinforcementInspectionPole .float-right {
  float: right;
}

.ReinforcementInspectionPole .mt-1rem {
  margin-top: 1rem;
}

.ReinforcementInspectionPole .mt-05rem {
  margin-top: 0.5rem;
}

.ReinforcementInspectionPole .ml-05rem {
  margin-left: 0.5rem;
}

.ReinforcementInspectionPole .mb-05rem {
  margin-bottom: 0.5rem;
}

.ReinforcementInspectionPole .mb-1rem {
  margin-bottom: 1rem;
}

.ReinforcementInspectionPole .mb-15rem {
  margin-bottom: 1.5rem;
}

.ReinforcementInspectionPole .ml-1rem {
  margin-left: 1rem;
}

.ReinforcementInspectionPole .collapse-button {
  float: right;
  padding: 2px 15px;
}

.ReinforcementInspectionPole .question-card {
  padding: 0.75rem;
}

.ReinforcementInspectionPole .question-card-title {
  margin-bottom: 0px;
}

.ReinforcementInspectionPole .card-header-primary {
  color: #ffffff;
  background-color: rgb(0, 123, 255);
}

.ReinforcementInspectionPole .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.ReinforcementInspectionPole .card-header-warning {
  color: #ffffff;
  background-color: rgb(255, 193, 7);
}

.ReinforcementInspectionPole .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.ReinforcementInspectionPole .small-italics {
  font-size: 80%;
  font-weight: 400;
  font-style: italic;
}

.ReinforcementInspectionPole .cursor-pointer {
  cursor: pointer;
}
