.CircuitAuditUtils .notification-padding {
  padding: 0.5rem;
}

.CircuitAuditUtils .mb-0rem {
  margin-bottom: 0rem;
}

.CircuitAuditUtils .mb-05rem {
  margin-bottom: 0.5rem;
}

.CircuitAuditUtils .mt-05rem {
  margin-top: 0.5rem;
}

.CircuitAuditUtils .mb-0rem {
  margin-bottom: 0rem;
}

.CircuitAuditUtils .ml-025rem {
  margin-left: 0.25rem;
}

.CircuitAuditUtils .mr-025rem {
  margin-right: 0.25rem;
}

.CircuitAuditUtils .display-card {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}

.CircuitAuditUtils .overflow-500 {
  max-height: 500px;
  overflow: scroll;
  font-size: small;
  padding: 0.5rem;
}

.CircuitAuditUtils .collapse-button {
  float: right;
  padding: 0.25px 15px;
}
