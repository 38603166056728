.DoAssocTableTest .card-header {
  font-weight: bold;
}

.DoAssocTableTest .mt-05rem {
  margin-top: 0.5rem;
}

.DoAssocTableTest .mt-1rem {
  margin-top: 1rem;
}

.DoAssocTableTest .mb-1rem {
  margin-bottom: 1rem;
}
