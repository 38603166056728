.ComplexRules {
  margin-top: 3rem !important;
}

.ComplexRules .UploadFile {
  padding: 5px;
  width: 100%;
  text-align: right;
}

.ComplexRules .UploadFile a {
  text-decoration: none;
}
