.MyDriveFile .card-text {
  color: #000000;
}

.MyDriveFile .card-header {
  color: #000000;
}

.MyDriveFile .file-card {
  margin-bottom: 1rem;
}

.MyDriveFile .deleteButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.MyDriveFile .shareButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.MyDriveFile .ml-0-25rem {
  margin-left: 0.25rem;
}

.MyDriveFile .vendor-list {
  margin-top: 1rem;
  margin-left: 0.5rem;
}
