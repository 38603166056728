.ReleasesList h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 28px;
}

.ReleasesList p {
  color: #999;
}

.ReleasesList .Scrollable {
  overflow-x: scroll;
}

.ReleasesList .CapPhrase {
  text-transform: capitalize;
}

.ReleasesList .card-header {
  font-weight: bold;
}

.ReleasesList .search-engine {
  margin-top: 1rem;
}

.ReleasesList .NewSearch {
  width: 100%;
  text-align: right;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.ReleasesList .ReleaseCards {
  margin-top: 1rem;
}

.ReleasesList .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.ReleasesList .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}
