.AzUsageDetails .card-header {
  font-weight: bold;
}

.AzUsageDetails .mt-1rem {
  margin-top: 1rem;
}

.AzUsageDetails .overflow-500 {
  max-height: 500px;
  overflow: scroll;
  font-size: small;
}
