.PhotoUpload .custom-file {
  margin-bottom: 5px;
}

.PhotoUpload .mb-05rem {
  margin-bottom: 0.5rem;
}

.PhotoUpload .photo-des {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.PhotoUpload .mt-05rem {
  margin-top: 0.5rem;
}

.PhotoUpload .mr-025rem {
  margin-right: 0.25rem;
}

.PhotoUpload .align-preview {
  text-align: center;
}

.PhotoUpload .card-header-warning {
  color: #ffffff;
  background-color: rgb(255, 193, 7);
}

.PhotoUpload .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.PhotoUpload .float-right {
  float: right;
}
