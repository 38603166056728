.ReleaseCreate .FormCard {
  margin-top: 1rem;
}

.ReleaseCreate .margin-bottom-1rem {
  margin-bottom: 1rem;
}

.ReleaseCreate .margin-bottom-125rem {
  margin-bottom: 1.25rem;
}

.ReleaseCreate .card-text {
  color: #000;
}

.ReleaseCreate .card-title {
  margin-bottom: 0.1rem;
}

.ReleaseCreate .padding-bottom-05rem {
  padding-bottom: 0.5rem;
}

.ReleaseCreate .group-container {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
