.Builder .Padding10px {
  padding: 10px;
}

.Builder .mt-1rem {
  margin-top: 1rem;
}

.Builder .mb-1rem {
  margin-bottom: 1rem;
}

.Builder .operator-inactive-button {
  color: #0262cc;
  background-color: #ffffff;
  border-color: #007bff;
}
