.ComplexRuleAdd .add-card-body {
  padding: 0rem;
  margin-top: 1rem;
}

.ComplexRuleAdd .card-text {
  color: #000000;
}

.ComplexRuleAdd .applyTo {
  margin-top: 1rem;
}

.ComplexRuleAdd .editButton {
  margin-top: 1rem;
}

.ComplexRuleAdd .creation-warning .card-body {
  background-color: #fff3cd;
  border-color: #ffeeba;
  margin-bottom: 1rem;
}

.ComplexRuleAdd .creation-warning h5 {
  color: #333;
}
