.Rep1Comp .card-header {
  font-weight: bold;
}

.Rep1Comp .mt-1rem {
  margin-top: 1rem;
}

.Rep1Comp .ml-025rem {
  margin-left: 0.25rem;
}

.Rep1Comp .mr-025rem {
  margin-right: 0.25rem;
}

.Rep1Comp .overflow-600 {
  max-height: 600px;
  overflow: scroll;
  font-size: small;
}

.Rep1Comp .overflow-1200 {
  max-height: 1200px;
  overflow: scroll;
  font-size: small;
}

.Rep1Comp .btw-2px {
  border-top-width: 2px;
}
