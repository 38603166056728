.ReinforcementInspectionList .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.ReinforcementInspectionList .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.ReinforcementInspectionList .NewSearch {
  width: 100%;
  text-align: right;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.ReinforcementInspectionList .card-header {
  font-weight: bold;
}

.ReinforcementInspectionList .search-engine {
  margin-top: 1rem;
}

.ReinforcementInspectionList .mt-1rem {
  margin-top: 1rem;
}

.ReinforcementInspectionList .mb-0rem {
  margin-bottom: 0rem;
}
