.SearchFileBox h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 28px;
}

.SearchFileBox p {
  color: #999;
}

.SearchFileBox .Download {
  text-align: center;
}

.SearchFileBox .Scrollable {
  overflow-x: scroll;
}

.SearchFileBox .CapPhrase {
  text-transform: capitalize;
}

.SearchFileBox .card-header {
  font-weight: bold;
}

.SearchFileBox .NewSearch {
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
  margin-top: -10px;
}

.ShowRuleErrorsModal .modal-body {
  overflow-y: auto;
  max-height: 600px;
}

.ShowRuleErrorsModal .card {
  margin-bottom: 15px;
}

.SearchFileBox .UploadCards {
  margin-top: 1rem;
}
